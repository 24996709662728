import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { ternary } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import { replaceLocation } from '../../Utils/window'
import {
  CREATE_AND_UPDATE_PROJECT_FAILED,
  CREATE_AND_UPDATE_PROJECT_REQUESTED,
  CREATE_AND_UPDATE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILED,
  DELETE_PROJECT_REQUESTED,
  DELETE_PROJECT_SUCCESS,
  FETCH_PROJECT_FIELD_CHOICES_REQUESTED,
  FETCH_PROJECT_LIST_FAILED,
  FETCH_PROJECT_LIST_REQUESTED,
  FETCH_PROJECT_LIST_SUCCESS,
  FETCH_PROJECT_SUCCESS,
  SET_FIELD_CHOICES_DATA,
} from '../constants/projects'
import { updateSaveStatusAction } from './confirmation'

// Fetch project list
const fetchProjectListRequested = () => ({
  type: FETCH_PROJECT_LIST_REQUESTED,
})
export const fetchProjectListSuccess = (payload) => ({
  type: FETCH_PROJECT_LIST_SUCCESS,
  payload,
})
export const fetchProjectSuccess = (payload) => ({
  type: FETCH_PROJECT_SUCCESS,
  payload,
})
const fetchProjectListFailed = (payload) => ({
  type: FETCH_PROJECT_LIST_FAILED,
  payload,
})

export const getProjectList =
  (id, isLoading = true) =>
  async (dispatch) => {
    if (isLoading) {
      dispatch(fetchProjectListRequested())
    }
    const { data, error } = await api({
      endPoint: ternary(
        id,
        `${apiEndPoints.project}${id}/`,
        `${apiEndPoints.project}`,
      ),
      method: method.get,
      showToastMessage: false,
      toastMessage: 'Project Fetch successfully!',
    })
    if (data) {
      if (id) {
        dispatch(fetchProjectSuccess(data))
      } else {
        dispatch(fetchProjectListSuccess(data))
      }
      return
    }
    if (error) {
      if (id && error.response?.status === 404) {
        replaceLocation('/projects')
      }
      dispatch(fetchProjectListFailed(error.response))
    }
  }
// Create and update projects
const createAndUpdateProjectRequested = () => ({
  type: CREATE_AND_UPDATE_PROJECT_REQUESTED,
})
const createAndUpdateProjectSuccess = (payload) => ({
  type: CREATE_AND_UPDATE_PROJECT_SUCCESS,
  payload,
})
const createAndUpdateProjectFailed = (payload) => ({
  type: CREATE_AND_UPDATE_PROJECT_FAILED,
  payload,
})

export const createAndUpdateProject =
  (payload, id, navigate) => async (dispatch, getState) => {
    const siteAddress = getState().siteAddress.siteAddressList
    dispatch(createAndUpdateProjectRequested())
    const { data, error } = await api({
      endPoint: ternary(
        id,
        `${apiEndPoints.project}${id}/`,
        `${apiEndPoints.project}`,
      ),
      method: ternary(id, method.patch, method.post),
      data: ternary(id, payload, { ...payload, siteAddress }),
      showToastMessage: true,
      toastMessage: ternary(
        id,
        'Project updated successfully!',
        'Project created successfully!',
      ),
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(createAndUpdateProjectSuccess(data))
      dispatch(getProjectList(id, false))
      if (!id) {
        navigate(`/projects/${data?.id}/summary/`)
      }
      return
    }
    if (error) {
      dispatch(createAndUpdateProjectFailed(error.response.data))
    }
  }

// Delete project
const deleteProjectRequested = () => ({
  type: DELETE_PROJECT_REQUESTED,
})
const deleteProjectSuccess = (payload) => ({
  type: DELETE_PROJECT_SUCCESS,
  payload,
})
const deleteProjectFailed = (payload) => ({
  type: DELETE_PROJECT_FAILED,
  payload,
})

export const deleteProject = (payload) => async (dispatch) => {
  dispatch(deleteProjectRequested())
  const { status, error } = await api({
    endPoint: apiEndPoints.deleteProject,
    method: method.delete,
    data: payload,
    showToastMessage: true,
    toastMessage: 'Project Deleted successfully!',
  })
  if (status) {
    dispatch(deleteProjectSuccess(payload.projects))
    return
  }
  if (error) {
    dispatch(deleteProjectFailed(error.response.data))
  }
}
export const fetchProjectFieldChoicesRequested = () => ({
  type: FETCH_PROJECT_FIELD_CHOICES_REQUESTED,
})

export const setFieldChoicesData = (payload) => ({
  type: SET_FIELD_CHOICES_DATA,
  payload,
})

export const fetchProjectFieldChoices = (payload) => async (dispatch) => {
  dispatch(fetchProjectFieldChoicesRequested())
  try {
    const { data } = await api({
      endPoint: `${apiEndPoints.projectChoices}?fieldName=${payload.join(',')}`,
      method: method.get,
      showToastMessage: false,
      toastMessage: '',
    })
    if (data) {
      dispatch(setFieldChoicesData(data))
    }
  } catch (error) {
    dispatch(createAndUpdateProjectFailed(error.response.data))
  }
}

// Download project excel file
export const downloadExcelFile = (id) => async () => {
  const { data, error } = await api({
    endPoint: `${apiEndPoints.downloadExcel}?company=${id}`,
    method: method.get,
    responseType: 'blob',
  })

  if (data) {
    const url = window.URL.createObjectURL(new Blob([data]))

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'data.xlsx')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  if (error) {
    showToast()
  }
}
