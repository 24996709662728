import moment from 'moment'

import imageCompression from 'browser-image-compression'
import axios from 'axios'
import { matchRoutes } from 'react-router-dom'
import heic2any from 'heic2any'
import {
  BOOLEAN,
  method,
  MIN_ONE,
  n,
  NUMBER,
  OBJECT,
  quality,
  STRING,
  y,
} from './constant'
import { notEmpty } from './regex'
import { minusSymbol } from './symbols'
import api from './api'

export const ternary = (bool, truthy, falsy) => (bool ? truthy : falsy)

export const equal = (obj1, obj2 = 0) => obj1 === obj2

export const head = (obj) => obj?.[0]

export const last = (obj) => obj[length(obj) - 1]

export const length = (obj) => obj?.length

export const entries = (obj) => (obj ? Object.entries(obj) : [])

export const values = (object) => (object ? Object.values(object) : [])

export const keys = (object) => (object ? Object.keys(object) : [])

export const isEmpty = (value) => {
  if (typeOf(value, STRING) && isEmptyString(value)) return true
  if (typeOf(value, OBJECT) && equal(length(keys(value)))) return true
  return false
}

export const isEmptyString = (value) =>
  equal(value, '') || checkUndefined(value) || equal(value, null)

export const lowerCase = (value) => value?.toLowerCase()

export const spaceBetween = (value) =>
  value?.replace(/([A-Z|0-9|_])/g, ' $1').trim()

export const getDate = (
  value,
  locale = 'en-US',
  format = 'YYYY-MM-DD HH:mm a',
) => moment.parseZone(value).local(false).locale(locale).format(format)

export const isArray = (obj) => Array.isArray(obj)

export const checkVal = (val) => val ?? ''

export const getName = (firstName, lastName) => {
  const name = `${checkVal(firstName)} ${checkVal(lastName)}`
  if (notEmpty(name)) return spaceBetween(name)
  return minusSymbol
}

export const checkUndefined = (obj) => obj === undefined

export const isBool = (value) => typeOf(value, BOOLEAN)

export const notNull = (value) => !equal(value, null)

export const checkIncludes = (value, array) => array?.includes(value)

export const modifyNumber = (value) => {
  const number = value?.toString().split('.')
  if (number) {
    if (gte(length(head(number)), 4)) {
      number[0] = head(number).replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    }
    if (number[1] && gte(length(number[1]), 5)) {
      number[1] = number[1].replace(/(\d{3})/g, '$1,')
    }
  }
  return number?.join('.')
}

export const capitalizeString = (val) =>
  upperCase(val?.charAt(0)) + lowerCase(val?.slice(1))

export const replaceChar = (val) => val.replace(/_/g, ' ')

export const getBoolean = (val) => ternary(val, y, n)

export const numberLocale = (num, locale = 'en-US') =>
  Number(num)?.toLocaleString(locale)

export const getMissingNumberArray = (betWeenVal) => {
  const missing = [betWeenVal[0]]
  for (let i = betWeenVal[0]; i <= betWeenVal[1]; i += 1) {
    if (equal(indexOf(betWeenVal, i), -1)) {
      missing.push(i)
    }
  }
  return [...missing, betWeenVal[1]]
}

// eslint-disable-next-line no-restricted-globals
export const numberArray = (array) => !array.some(isNaN)

export const multiFilter = (array, filters) => {
  const filterKeys = keys(filters)
  return array?.filter((item) =>
    filterKeys?.every((key) => {
      if (!filters[key]?.length) return true
      if (getKeyType(filters[key]) && gt(length(filters[key]), MIN_ONE))
        return filters[key].reduce(
          (cur, prev) => item[key] <= prev && gte(item[key], cur) && item[key],
        )
      return filters[key]?.includes(item[key])
    }),
  )
}
const getKeyType = (array) => {
  const isValidNumber = array.every((element) => typeOf(element, NUMBER))
  if (isValidNumber) return isValidNumber
  if (!isValidNumber) {
    return array.every((element) => {
      const date = new Date(element)
      return date instanceof Date && !Number.isNaN(date.valueOf())
    })
  }
  return false
}

export const getSkeletonsLength = (array) =>
  length(array) -
  length(
    array
      .map(
        ({ options }) =>
          !options.display &&
          !checkUndefined(options.display) &&
          !options.display,
      )
      .filter(Boolean),
  )

export const getMinDate = (arr) =>
  getDate(moment.min(arr?.map((dt) => moment(dt))), null, 'YYYY-MM-DD')

export const getMaxDate = (arr) =>
  getDate(moment.max(arr?.map((dt) => moment(dt))), null, 'YYYY-MM-DD')

export const getDifferenceInDays = (date1, date2) =>
  moment(date1).diff(moment(date2), 'days')

export const findTotal = (key, data) =>
  data?.reduce((n, { [key]: totalVal }) => n + totalVal, 0)

export const findUniqueFromArrayObject = (array, key) => [
  ...new Map(array?.map((item) => [item[key], item])).values(),
]

export const typeOf = (val, type) => equal(typeof val, type)

export const gt = (param1, param2) => param1 > param2

export const lt = (param1, param2) => param1 < param2

export const gte = (param1, param2) => param1 >= param2

export const lte = (param1, param2) => param1 <= param2

export const upperCase = (value) => value?.toUpperCase()

export const checkPercentageValue = (value) => gte(value, 0) && lte(value, 100)

export const indexOf = (string, val) => string.indexOf(val)

export const handleTextEditor = (editor, name, setFieldValue) => {
  const data = editor.getData()
  setFieldValue(name, data)
}

// Blue all active elements
export const handleBlurAll = () => {
  document.activeElement?.blur()
}

// compare two arrays
export const compareArrays = (arr1, arr2) => {
  const objMap = []
  if (arr1?.length === arr2?.length) {
    arr2?.forEach((value) => {
      objMap.push(!arr1?.some((item) => item === value))
    })
  } else {
    objMap.push(true)
  }
  return objMap
}

// convert base64 encoded string to file
export const base64ToFile = (base64Data, filename) => {
  const arr = base64Data.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

// convert bytes
export const byteConversion = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 B'

  const kiloByte = 1000
  const decimal = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(kiloByte))

  return `${parseFloat((bytes / kiloByte ** i).toFixed(decimal))} ${sizes[i]}`
}

// render html content
export const renderHtmlContent = (html) => ({ __html: html })

export const compressImage = async (originalImageFile, uploadLimit) => {
  const compressWithQuality = async (currentQuality) => {
    const options = {
      maxSizeMB: uploadLimit,
      alwaysKeepResolution: true,
      initialQuality: currentQuality,
    }

    const compressedBlob = await imageCompression(originalImageFile, options)

    if (
      compressedBlob?.size > uploadLimit * 1024 * 1024 &&
      currentQuality > 0
    ) {
      const newQuality = currentQuality - 0.1
      const nextQuality = newQuality < 0 ? 0 : newQuality

      return compressWithQuality(nextQuality)
    }

    return new File([compressedBlob], `${originalImageFile.name}`, {
      type: originalImageFile.type,
      lastModified: originalImageFile.lastModified,
    })
  }

  return compressWithQuality(quality)
}

export const handleDownloadImage = async (downloadImageLink, fileName) => {
  const a = document.createElement('a')
  const response = await api({
    urlEndPoint: `${downloadImageLink}?query=${new Date().getTime()}`,
    method: method.get,
    responseType: 'blob',
    attachAccessToken: false,
  })
  a.href = URL.createObjectURL(response.data)
  a.download = `${fileName}`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

// scroll to element
export const scrollToElement = (element) => {
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

let timeoutId = null
export const debounceFunction =
  (func, delay) =>
  (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }

export const downloadItem = (data, fileType, filename) => {
  const blobData = new Blob([data], { type: fileType })
  const downloadUrl = window.URL.createObjectURL(blobData)
  const link = document.createElement('a')
  link.href = downloadUrl
  link.setAttribute('download', filename)

  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

export const cancelApiCallSource = () => axios.CancelToken.source()

export const matchRoutesLink = ({ path, location }) =>
  matchRoutes([{ path }], location)

export const htmlToText = (html) => {
  const tempElement = document.createElement('div')
  tempElement.innerHTML = html
  return tempElement.textContent || tempElement.innerText || ''
}

export const replaceMentionsWithValues = (
  editorContent,
  values,
  valuesFormatter = () => {},
) =>
  editorContent.replace(
    /<span class="mention" data-mention="(.*?)">(.*?)<\/span>/g,
    (match, label) => {
      const value = values[label.slice(1)]
      return ternary(
        isEmptyString(value),
        '<span style="color: red;">Error</span>',
        valuesFormatter(label, value),
      )
    },
  )

export const convertImgType = async (file) => {
  const convertedBlob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
  })
  const convertedFile = new File([convertedBlob], `${file.name}`, {
    type: 'image/jpeg',
  })
  return convertedFile
}
